.mat-mdc-form-field {
  width: 245px;
  height: 40px;
  border: 1px solid var(--primary-border-color);
  border-radius: 8px;
  background-color: var(--bg-color-white);
}

.mat-mdc-form-field-infix {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.mat-mdc-select-value {
  width: 20px;
}

.mat-mdc-select {
  width: fit-content !important;
}

.mat-mdc-select-trigger {
  width: auto;
}

.mat-mdc-select-value {
  width: auto;
}

.mat-mdc-form-field-flex {
  height: 40px;
  display: flex !important;
  align-items: center !important;
}

.mdc-notched-outline__trailing {
  border: none !important;
}

.mdc-notched-outline__leading {
  border: none !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  display: none;
}

.mat-mdc-select-arrow {
  display: none;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-text-field-wrapper {
  padding: 0 !important;
  background-color: var(--transparent-color) !important;
  border-radius: 8px;
}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-select-panel {
  background-color: var(--bg-color-white) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: var(--secondary-bacground-color) !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  color: var(--bg-color-black) !important;
}

.mat-mdc-select-panel {
  margin-top: 8px;
}

.mat-mdc-form-field-infix {
  padding: 0;
  justify-content: center;
}
