@font-face {
    font-family: 'Open-Sans';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/open-sans/OpenSans-Regular.woff') format('woff');
}



@font-face {
    font-family: 'Open-Sans Medium';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/open-sans/OpenSans-Medium.woff') format('woff');
}