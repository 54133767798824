:root {
  --bg-color-white: #ffffff;
  --bg-color-black: #000000;
  --primary-background: rgba(246, 246, 246, 1);
  --primary-border-color: rgba(236, 236, 236, 1);
  --action-color: rgba(0, 96, 251, 1);
  --action-color-disabled: rgba(204, 223, 254, 1);
  --action-border-color: rgba(0, 96, 251, 0.3);
  --cancel-color: rgba(17, 19, 20, 1);
  --secondary-bacground-color: rgba(234, 234, 234, 1);
  --transparent-color: rgba(255, 255, 255, 0);
  --third-bacground-color: rgba(237, 237, 237, 1);
  --link-hiver-color: rgba(0, 96, 251, 0.3);
  --box-shadow-card-color: rgba(0, 96, 251, 0.15);
  --active-location-background: rgba(0, 96, 251, 0.1);
  --header-location-border: rgba(17, 19, 20, 1);
  --header-title-color: rgba(126, 126, 126, 1);
  --header-active-background: rgba(230, 239, 255, 1);
  --secondary-border-color: rgba(228, 228, 228, 1);
  --fill-color-svg: rgba(141, 141, 141, 1);
  --fill--inactive-color-svg: rgba(198, 198, 198, 1);
  --status-text-color: rgba(80, 184, 25, 1);
  --status-bakground-color: rgba(80, 184, 25, 0.16);
  --done-button-hover-background: rgba(214, 214, 214, 1);
  --done-button-active-background: rgba(188, 188, 188, 1);
  --cancel-button-hover-background: rgba(222, 222, 222, 1);
  --cancel-button-active-background: rgba(213, 213, 213, 1);
  --preview-img-background: rgba(246, 247, 250, 1);
  --box-shadow-login: rgba(0, 0, 0, 0.06);
  --error-color: rgba(225, 42, 2, 1);
  --empty-color: rgba(255, 255, 255, 0);
  --point-background-color: rgba(224, 224, 224, 1);
  --spiner-background: rgba(0, 0, 0, 0.13);
  --status-work-text-color: rgba(255, 117, 25, 1);
  --status-work-bakground-color: rgba(255, 117, 25, 0.16);
  --status-planned-bakground-color: rgba(0, 96, 251, 0.16);
  --time-window-background-color: rgba(0, 96, 251, 0.2);
  --arrow-calendar-color: rgba(157, 157, 176, 1);
  --second-border-color: rgba(200, 200, 200, 1);
  --light-border-color: #ECECEC;
  --text-color-primary: rgba(170, 170, 170, 1);
  --text-color-secondary: #111314;
  --error-indicator-center-background: rgba(207, 49, 44, 1);
  --error-indicator-border-color: rgba(207, 49, 44, 0.16);
  --toast-error-text-color: rgba(255, 0, 0, 1);
  --toast-error-backgound-color: rgba(252, 233, 229, 1);
  --toast-success-backgound-color: rgba(238, 248, 232, 1);
  --action-buttons-border-color: #ECECEC;
  --highlighted-text-background: rgba(0, 96, 251, 0.1);
;

  // typography
  --page-title-line-height: 2rem;
  --page-title-font-size: 1.25rem;
  --regular-font-size: 1rem;

  // spacings
  --title-horizontal-padding: 56px;
  --title-vertical-padding: 16px;
  --field-gap: 16px;

  // borders
  --page-border-radius: 16px;
}
